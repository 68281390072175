import { nanoid } from "nanoid";

// HEAD DATA
export const headData = {
  title: "Iden Han (@idenhan) - Front-End Developer", // e.g: 'Name | Developer'
  lang: "en", // e.g: en, es, fr, jp
  description: "Welcome to my website", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Hi, my name is",
  name: " Iden Han",
  subtitle: "I'm the Front-End Developer.",
  cta: "Know more",
};

// ABOUT DATA
export const aboutData = {
  img: "pic_in_seoul.jpg",
  paragraphOne:
    "I believe that environmental protection can be achieved through the use of technology. I am passionate about learning and staying current with the latest and greatest technologies in both the frontend and backend of software development.",
  paragraphTwo:
    "I enjoy creating sleek, responsive, and user-friendly websites, and I thrive in fast-paced environments. My diverse work experiences have allowed me to excel at collaborating with teams of varying knowledge sets. A versatile engineer with interests in Software Engineering and Machine Automation. Adaptable and self-motivated learner. Interested in environmental and new programming technologies, and continuous self-improvement.",
  paragraphThree:
    "Always open to opportunities within the software engineering discipline and if you feel like we would be a great match, I’d love to hear from you.",
  resume: "https://www.resumemaker.online/es.php", // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: "shakespearesglobe.png",
    title: "Shakespeare's Globe",
    info: "React / Context API / Bootstrap / Styled-Components / Azure",
    info2: "",
    url: "https://www.shakespearesglobe.com/",
    repo: "", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "honolulu.png",
    title: "Honolulu",
    info: "React / Redux / Sass / GraphQL",
    info2: "",
    url: "https://honoluluspace.com/",
    repo: "", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "leanai.png",
    title: "Lean AI SaaS",
    info: "React / Redux-Saga / Websocket / Styled-Components / AWS",
    info2: "",
    url: "https://leanai.algorithmlabs.io/",
    repo: "", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "meercat.png",
    title: "Meercat Mobile Web App",
    info: "React / Sentry / Styled-Components",
    info2: "",
    url: "http://meercat.io",
    repo: "", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "nuri.png",
    title: "NURI",
    info: "Next.js / React / CSS",
    info2: "",
    url: "https://nuri.vercel.app",
    repo: "https://github.com/idenhan/nuri", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "aisaasforhyundai.png",
    title: "HRD AI SaaS For Hyundai",
    info: "React / Redux-Saga / Styled-Components / Azure",
    info2: "",
    url: "http://hyundai-admin.algorithmlabs.io",
    repo: "", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "thoi.png",
    title: "THOI",
    info: "React / Sass",
    info2: "",
    url: "https://idenhan.github.io/top-100-of-itunes",
    repo: "https://github.com/idenhan/top-100-of-itunes", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "toggle.png",
    title: "Toggle",
    info: "React / Redux / CSS",
    info2: "",
    url: "https://toggle.ly",
    repo: "", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "toggle_admin.png",
    title: "Toggle Admin",
    info: "React / Redux / CSS",
    info2: "",
    url: "https://mng.toggle.ly",
    repo: "", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "github_friends.png",
    title: "Github Friends",
    info: "Typescript / Javascript",
    info2: "Mobile Web Application",
    url: "http://github-friends.s3-website.us-east-2.amazonaws.com/",
    repo: "https://github.com/idenhan/github-friends", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "legalengine.png",
    title: "LegalEngine",
    info: "React / Redux / Bootstrap",
    info2: "",
    url: "https://legalengine.co.kr",
    repo: "", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "netflix.png",
    title: "Netflix Web Clone",
    info: "Javascript / HTML / CSS",
    info2: "",
    url: "",
    repo: "https://github.com/idenhan/Netflix-Responsive-Web-Clone", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "apple.png",
    title: "Apple Web Clone",
    info: "Javascript / HTML / CSS",
    info2: "",
    url: "",
    repo: "https://github.com/idenhan/Apple-Responsive-Web-Clone", // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: "Have a question or want to work with me?",
  btn: "Let's Talk",
  email: "idenhan129@gmail.com",
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: "github",
      url: "https://github.com/idenhan",
    },
    {
      id: nanoid(),
      name: "linkedin",
      url: "https://linkedin.com/in/idenhan",
    },
    {
      id: nanoid(),
      name: "instagram",
      url: "https://instagram.com/idenhan",
    },
    {
      id: nanoid(),
      name: "youtube",
      url: "https://www.youtube.com/watch?v=n6WaTObHRJM",
    },
  ],
};
